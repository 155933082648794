<template>
  <div   >

    <v-dialog  v-model="dialog"     max-width="800px">

        <v-card>
          <v-toolbar  dark color="primary">

                <v-toolbar-title>Produits</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn  icon   dark  @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="3" >
                  <v-card-title>ÉPICERIE</v-card-title>
                  <template v-for="(item, i) in items_epic">

                      <v-checkbox dense :key="'epic'+ i" v-model="categories[item.name]" :label="item.text" @click="changeSearch"></v-checkbox>
                  </template>
                </v-col>
                <v-col cols="12" md="3" >
                  <v-card-title>MODE</v-card-title>
                  <template v-for="(item, i) in items_mode">

                      <v-checkbox dense :key="'mode'+ i" v-model="categories[item.name]" :label="item.text" @click="changeSearch"></v-checkbox>
                  </template>
                </v-col>
                <v-col cols="12" md="3" >
                  <v-card-title>BÉAUTÉ ET HYGIÈNE</v-card-title>
                  <template v-for="(item, i) in items_beaute">

                      <v-checkbox dense :key="'mode'+ i" v-model="categories[item.name]" :label="item.text" @click="changeSearch"></v-checkbox>
                  </template>
                </v-col>
                <v-col cols="12" md="3" >
                  <v-card-title>LOISIRS</v-card-title>
                  <template v-for="(item, i) in items_loisir">

                      <v-checkbox dense :key="'loisir'+ i" v-model="categories[item.name]" :label="item.text" @click="changeSearch"></v-checkbox>
                  </template>
                </v-col>
                <v-col cols="12" md="3" >
                  <v-card-title>MAISON</v-card-title>
                  <template v-for="(item, i) in items_maison">

                      <v-checkbox dense :key="'maison'+ i" v-model="categories[item.name]" :label="item.text" @click="changeSearch"></v-checkbox>
                  </template>
                </v-col>
              </v-row>
            </v-container>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="dialog = false"
            >
              Fermer
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="dialog = false"
            >
              Valider
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  <v-card class="grey lighten-3"  :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'" >


  <!-- Stack the columns on mobile by making one full-width and the other half-width -->

  <v-row no-gutters>
    <v-col  cols="12"  md="5">
      <v-card>
      <mapi :data="commerce_search" @changeDistance="(v) => changeDistance(v)" @changeposition="(v) => changeposition(v)"></mapi>
      </v-card>
    </v-col>




    <v-col  cols="12"  md="7">


<v-container>
  <v-card>
  <v-card-text>
            <v-row>
              <v-col cols="12">

             <v-autocomplete
    v-model="model_search"
    :items="items"
    :loading="isLoading"
    @change="getautosearch"
    color="primary"
    hide-no-data
    hide-selected
    multiple
    clearable
    item-text="Description"
    item-value="API"
    label=""
    placeholder="Rechercher un nom de commerce"
    prepend-icon="mdi-store"
    return-object
  ></v-autocomplete>
           </v-col>
              <v-col>

                <v-btn   class="select_design" @click="dialog = true" block dense color="primary">Produits</v-btn>
              </v-col>

              <v-col>
                <v-menu v-model="menu_payment" :close-on-content-click="false" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn block dense  color="primary" v-bind="attrs" v-on="on">
                      Moyens de paiement
                      </v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-for="item in items_payment" :key="item.name">
                        <v-checkbox v-model="payment" :value="item.name" :label="item.text" @click="changeSearch()"></v-checkbox>
                        </v-list-item>
                      </v-list>
                  </v-menu>
              </v-col>
              <v-col>
                <v-menu v-model="menu_livraison" :close-on-content-click="false" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn block dense  color="primary" v-bind="attrs" v-on="on">
                      Options de livraison
                      </v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-for="item in items_expe" :key="item.name" >
                        <v-checkbox v-model="livraison" :value="item.name" :label="item.text" @click="changeSearch()"></v-checkbox>
                        </v-list-item>
                      </v-list>
                  </v-menu>
              </v-col>


</v-row>
</v-card-text>
</v-card>
<template v-for="(p, i) in global_categories" >
  <v-chip v-if="categories[p] == true" :key="'categories'+ i" class="ma-2"  close label @click:close="categories[p] = false, changeSearch()" tile>

      {{GetNameClass(items_epic.concat(items_mode,items_beaute, items_loisir, items_maison), p)}}
      </v-chip>
</template>

<template v-for="(p, i) in payment" >
  <v-chip  :key="'payment'+ i" class="ma-2"  close label @click:close="payment.splice(payment.indexOf(p), 1), changeSearch()" tile>

      {{GetNameClass(items_payment, p)}}
      </v-chip>
</template>
<template v-for="(p, i) in livraison" >
  <v-chip  :key="'livraison'+ i" class="ma-2"  close label @click:close="livraison.splice(livraison.indexOf(p), 1), changeSearch()" tile>

      {{GetNameClass(items_expe, p)}}
      </v-chip>
</template>
<template v-if="payment.length >0 || livraison.length  > 0 || global_categories.length  > 0">
  <v-chip  class=""   color="primary" label @click="payment = [], livraison = [], global_categories = [], categories = {}, changeSearch()" tile>

  Vider les filtres
      </v-chip>
</template>





      <v-row >
        <v-col v-for="(v, i) in commerce_search_comp" :key="i" cols="12"  lg="4" md="6" sm="12" >
          <v-card   color="grey lighten-5">
            <v-theme-provider dark>
              <div>
                <v-img
height="120"
:src="v.tab_img[0].url_norm"
:lazy-src="v.tab_img[0].url_mini"
></v-img>


              </div>
            </v-theme-provider>


            <v-card-text>
              <v-row>
              <v-col >
          <b>{{v.name_commerce}}</b><br> {{v.adress_data.locality}}
                </v-col>
          <v-col class="text-right">
            <v-btn right  outlined  :to="'/commerce/' + v.id">
              <v-icon dark>
             mdi-basket
           </v-icon>
            </v-btn>
      </v-col>
    </v-row>
            </v-card-text>

          </v-card>
        </v-col>
      </v-row>

      <v-pagination
      v-model="page"
      :length="nb_page"
      prev-icon="mdi-menu-left"
      next-icon="mdi-menu-right"
    ></v-pagination>
      </v-container>
    </v-col>






  </v-row>






  </v-card>

</div>

</template>

<script>
import mapi from '@/components/map'
import firebase from "firebase";
import VueGeolocation from 'vue-browser-geolocation';
    export default {
      components: {
        mapi
      },
        data () {
            return {
              menu_payment:false,
              menu_livraison:false,
              dialog:false,
              distance: { label: 'Distance', val: 50, color: 'black' },
              commerce:[
                  {"id":0,"latitude":"46.417654962363535","longitude":"5.539882779312167","nom":"Banane land","type":"Mode", "class":['fruits_legumes','viandes_poissons','pains_patisseries']},
                  {"id":1,"latitude":"46.69036393455865","longitude":"5.638856292067299","nom":"Pouet","type":"Boissons", "class":['viandes_poissons','pains_patisseries']},
                  {"id":2,"latitude":"46.35778677020632","longitude":"5.896318853110014","nom":"PAAAATER","type":"Bébé", "class":['bebe','bijoux_montres']},
                  {"id":3,"latitude":"46.359249146724025","longitude":"5.880002974813587","nom":"CAROTTE","type":"Bio", "class":['maquillage','soins_visage']},
                  {"id":4,"latitude":"46.390320693839335","longitude":"5.895189643395238","nom":"KIVYYYYYYYY","type":"Vêtements", "class":['high_tech','sport']},
                  {"id":5,"latitude":"46.402151710588505","longitude":"5.878310501734063","nom":"POUTPOUT MANGE DE LA PURER","type":"Sport", "class":['decoration','meubles']}],

           commerce_search:[],
           min:0,
           page:1,
           nb_page:0,
           max:10000,
           items_epic: [
  {id:0,name:'fruits_legumes', text:'Fruits et légumes' },
  {id:1,name:'viandes_poissons', text: 'Viandes et poissons ' },
  {id:2,name:'pains_patisseries', text: 'Pains et pâtisseries' },
  {id:3,name:'frais', text: 'Frais' },
  {id:4,name: 'epicerie_salee', text:'Épicerie salée ' },
  {id:5,name:'epicerie_sucree', text: 'Épicerie sucrée ' },
  {id:6,name:'bio', text: 'Bio et écolo ' },
  {id:7,name:'boissons', text: 'Boissons ' },
  ],
           items_mode :[
           {name:'vetements', text:  'Vêtements ' },
           {name:'chaussures', text:  'Chaussures ' },
           {name:'bijoux_montres', text:  'Bijoux et montres ' },
           {name:'maroquinerie_bagagerie', text:  'Maroquinerie et Bagagerie ' },
           {name:'sport', text:  'Sport ' },
           ],
           items_beaute:[
           {name: 'maquillage', text:'Maquillage ' },
           {name: 'soins_visage', text:'Soins visage' },
           {name: 'soins_corps', text:'Soins corps ' },
           {name: 'dentaire', text:'Hygiène dentaire ' },
           {name: 'capillaire', text:'Capillaire ' },
           {name:'bebe', text: 'Bébé ' },
         ],
         items_loisir:[
         {name:'livres', text: 'Livres ' },
         {name:'jeux', text: 'Jeux' },
         {name: 'high_tech', text:'High-Tech ' },
         {name:'sport', text:'Sport ' },

       ],
       items_maison: [
       {name:'decoration', text:  'Décoration ' },
       {name:'art_table', text:  'Art de la table' },
       {name:'meubles', text:  'Meubles' },
       {name:'entretien_nettoyage', text:  'Entretien et nettoyage ' },
       {name:'bricolage_jardin', text:  'Bricolage et Jardin ' },
  ],
  items_payment: [
  {name:'especes', text:  'Espèces' },
  {name:'carte', text:  'Carte bancaire' },
  {name:'cheques', text:  'Chèques' },
  ],
  items_expe: [
  {name:'commerce', text:  'Retrait en magasin' },
  {name:'colis', text:  'Livraison à domicile' },
  ],
categories:{},
global_categories:[],
payment:[],
livraison:[],

     selec_epic:[],
     selec_mode:[],
     selec_beaute:[],
     selec_loisir:[],
     selec_maison:[],
     coordinates:null,
     descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model_search: null,
      search: null,


            }
        },
        mounted() {

    VueGeolocation.getLocation()
    .then(coordinates => {
      console.log(coordinates);
      this.coordinates = coordinates;
      this.loaddata(coordinates, this.distance.val )
    });
  },
  computed: {
        fields () {
          if (!this.model) return []

          return Object.keys(this.model).map(key => {
            return {
              key,
              value: this.model[key] || 'n/a',
            }
          })
        },
        items () {
          return this.commerce.map(entry => {
            const Description = entry.name_commerce

            return Object.assign({}, entry, { Description })
          })
        },

  commerce_search_comp(){
    return this.page ? this.commerce_search.slice((this.page-1)*9,this.page*9) : this.commerce_search
  }

      },

      watch: {
        search () {
          // Items have already been loaded
          if (this.items.length > 0) return

          // Items have already been requested
          if (this.isLoading) return

          this.isLoading = true

          // Lazily load input items
          fetch('https://api.publicapis.org/entries')
            .then(res => res.json())
            .then(res => {
              const { count, entries } = res
              this.count = count
              this.entries = entries
            })
            .catch(err => {
              console.log(err)
            })
            .finally(() => (this.isLoading = false))
        },
      },
  methods:{
    GetNameClass(array, val) {
      console.log(array)
      if(array.length != undefined) {
        for (var i = 0; i < array.length; i++) {

        if(array[i].name == val) {

          return array[i].text;

        }
        }
      }


    },
      loaddata(coordinates, distance) {
        this.commerce = []
        this.$db.collection("commerce").near({ center: new firebase.firestore.GeoPoint(coordinates.lat, coordinates.lng), radius: distance }).get().then((querySnapshot) => {
       console.log(querySnapshot)
       querySnapshot.forEach((doc) => {
        var data;
        data = doc.data()
        data.id = doc.id;
        this.commerce.push(data)

        this.nb_page = Math.ceil(this.commerce.length/9)

      });
      console.log(this.commerce)
      this.changeSearch()

     });


      },
      changeDistance(v) {

            this.loaddata(this.coordinates, v )
            this.distance.val = v
      },
      changeposition(v) {

            this.loaddata(v,  this.distance.val)
            this.coordinates = v;
      },
      getNamescategories() {
        let ar = []
        Object.getOwnPropertyNames(this.categories).forEach((val) => {

          if(this.categories[val] == true) {
            ar.push(val)
          }

  });
  this.global_categories = ar;
  return ar;

      },
      getautosearch(){
        if(this.model_search.length > 0) {

            this.commerce_search = this.model_search
              this.nb_page = Math.ceil(this.commerce_search.length/9)
        } else {
          this.changeSearch()
        }

      },
    changeSearch() {

      //  let ar = this.selec_epic.concat(this.selec_mode,this.selec_beaute, this.selec_loisir, this.selec_maison)
      let ar = this.getNamescategories();

var search_cat = (ar.length > 0)
var search_payment = (this.payment.length > 0)
var search_livraison = (this.livraison.length > 0)

  let tab_key = [];
  var push_val = false;
      //let ar = Object.getOwnPropertyNames(arr_true)

        console.log(ar)
        let tab_commerce = []
        if(search_cat || search_payment || search_livraison) {
        this.commerce.forEach((item) => {
          console.log(item)

          push_val = true;
            if(search_cat) {
                for (var i = 0; i < ar.length; i++) {
                  if(!item.class.includes(ar[i])) {
                  push_val = false;
                  break;
                  }
                }
          }

            if(search_payment) {
               tab_key = Object.keys(item.payment)
            for (i = 0; i <this.payment.length; i++) {

              if(!tab_key.includes(this.payment[i])) {
                push_val = false;
              break;
              }
            }
          }
          if(search_livraison) {
              tab_key = Object.keys(item.reception)
            for (i = 0; i < this.livraison.length; i++) {

              if(!tab_key.includes(this.livraison[i])) {
                push_val = false;
              break;
              }
            }
          }

            if(push_val &&  !tab_commerce.includes(item)) {
              tab_commerce.push(item)
            }



        });
        console.log(tab_commerce)
        this.nb_page = Math.ceil(tab_commerce.length/9)
        this.commerce_search = tab_commerce;
      } else {
          this.commerce_search = this.commerce
      }


    }


  }
    }
</script>
<style>
  .v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .5;
    position: absolute;
    width: 100%;
  }
  .v-main__wrap {
    background-color:#eee;
  }
  .treeclass {

    background-color:#B0C5B9;
  }

  .v-treeview-node--leaf  {
        background-color:#E3EAE5;

  }

  .v-treeview-node--active {
    color:red;

  }



</style>
