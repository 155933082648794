// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import App from './App'
import { router } from './router'
import firebase from 'firebase/app';
import store from "./store";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";


import * as geofirestore from 'geofirestore';

Vue.config.productionTip = false


const configOptions = {
  apiKey: "AIzaSyDquewUpTPYjGQkOxrGNbysX1B1qMl4qaA",
  authDomain: "commelocal.firebaseapp.com",
  databaseURL: "https://commelocal.firebaseio.com",
  projectId: "commelocal",
  storageBucket: "commelocal.appspot.com",
  messagingSenderId: "798716447346",
  appId: "1:798716447346:web:09e99418f1cf19f15810eb",
  measurementId: "G-7NYNM2VZK8"
};

const firebaseApp = firebase.initializeApp(configOptions);

 firebase.analytics();
geofirestore.initializeApp(firebaseApp);
let db = firebaseApp.firestore();
db = geofirestore.initializeApp(db)

Vue.prototype.$db = db;

let app;
firebase.auth().onAuthStateChanged(user => {
  console.log("user", user)

  if(!app) {
      app = new Vue({
        router,
        vuetify,
        store,
        render: h => h(App),
      }).$mount('#app')
  }
});
