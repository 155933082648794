<template>
  <div>

  <v-app id="inspire">

    <v-app-bar
    app dense
      v-if="isMobile"
      elevate-on-scroll

    >
      <v-app-bar-nav-icon @click.stop="drawer = true"></v-app-bar-nav-icon>

      <v-btn to="/map" icon>
        <v-icon>mdi-map</v-icon>
      </v-btn>

    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      :permanent="!isMobile"

      app

    >
      <v-list-item class="px-2">
        <v-list-item-icon>
            <v-img src="/img/icon/handshake.png"></v-img>
        </v-list-item-icon>


        <v-list-item-title>SOLVE</v-list-item-title>

        <v-btn
          icon
          @click.stop="mini = !mini"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item to="/" link>
          <v-list-item-icon>
            <v-icon>mdi-home-city</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Accueil</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to='/map' link>
          <v-list-item-icon>
            <v-icon>mdi-map</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Carte </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="!loggedIn" to="/connect" >
    <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
        <v-list-item-title>Se connecter</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="loggedIn" to="/account" >
          <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
        <v-list-item-title>Mon compte</v-list-item-title>
      </v-list-item>

      <v-list-item v-if="loggedIn" @click="logout" >
          <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
        <v-list-item-title>Se deconnecter</v-list-item-title>
      </v-list-item>



      </v-list>
    </v-navigation-drawer>


    <v-main>

      <router-view />
    </v-main>



    <v-footer
      :padless="true"
    >
      <v-card
        flat
        tile
        width="100%"
        class="secondary  white--text text-center"
      >

        <v-card-text class="white--text pt-0 secondary lighten-1">
          <v-container>
          <v-row>




            <v-col
              cols="12"
              md="6"

            >

              <v-row>
                  <v-col cols="3" sm="3" md="3">

                  </v-col>
                  <v-col cols="4" sm="3" md="3">
                      <p class="white--text mt-3 text-left"><b>CONTACT</b></p>
                  </v-col>
                  <v-col cols="5" sm="6" md="6">

                  </v-col>
              </v-row>
              <v-row>
                  <v-col cols="3" sm="3" md="3">
  <v-avatar  color="primary"><v-icon class="elevation-4" dark> mdi-email-outline</v-icon></v-avatar>
                  </v-col>
                  <v-col cols="4" sm="3" md="3">
                        <p class="white--text mt-3 text-left">EMAIL</p>
                  </v-col>
                  <v-col cols="5" sm="6" md="6">
                          <p class="white--text mt-3  text-left">contact@solve.community</p>
                  </v-col>
              </v-row>
              <v-row>
                  <v-col cols="3" sm="3" md="3">
  <v-avatar  color="primary"><v-icon class="elevation-4" dark> mdi-map-marker</v-icon></v-avatar>
                  </v-col>
                  <v-col cols="4" sm="3" md="3">
                        <p class="white--text mt-3 text-left">ADRESSE</p>
                  </v-col>
                  <v-col cols="5" sm="6" md="6">
                          <p class="white--text mt-3  text-left"> 7 rue du bois Joli, 39270 ORGELET</p>
                  </v-col>
              </v-row>
              <v-row>
                  <v-col cols="3" sm="3" md="3">
  <v-avatar  color="primary"><v-icon class="elevation-4" dark> mdi-phone</v-icon></v-avatar>
                  </v-col>
                  <v-col cols="4" sm="3" md="3">
                        <p class="white--text mt-3 text-left">TELEPHONE</p>
                  </v-col>
                  <v-col cols="5" sm="6" md="6">
                          <p class="white--text mt-3  text-left"> 06 81 74 60 15</p>
                  </v-col>
              </v-row>



              <v-list class="transparent white--text">



              </v-list>
            </v-col>


<v-col cols="12" md="3">
</v-col>
<v-col cols="12" md="3">
  <v-img  height="100" class="mt-8 mb-8" contain src="/img/logo/logo.png"
></v-img>
<v-btn dark  icon href="https://www.facebook.com/Solve-103815991552538/" target="_blank" text><v-icon large>mdi-facebook</v-icon></v-btn>
<v-btn dark  icon href="https://www.instagram.com/solve.community/" target="_blank" class="mx-2"  text><v-icon dark large>mdi-instagram</v-icon></v-btn>
<v-btn dark  icon href="https:///linkedin.com" target="_blank" text><v-icon dark large>mdi-linkedin</v-icon></v-btn>

</v-col>
          </v-row>
        </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text">
          {{ new Date().getFullYear() }}  <strong>Solve</strong> - <a class="white--text" href="/mentions">Mentions légales et politique de confidentialité</a>
        </v-card-text>
      </v-card>
    </v-footer>

    <cookie-law>


    <div slot-scope="props">
      Vous consentez à nos cookies si vous continuez à utiliser notre site Web. Pour en savoir plus, nous vous invitons à consulter notre <a href="/mentions#cookies">politique en matière de confidentialité et de cookies</a>.

      <v-btn @click="props.accept" class="primary text-end">Accepter</v-btn>

    </div>

  </cookie-law>
  </v-app>

</div>
</template>
<script>

import firebase from "firebase";
import CookieLaw from 'vue-cookie-law'
    export default {
      components:{
        CookieLaw
      },
        data () {
            return {
              is_round:false,
                drawer: true,
              items: [
                { title: 'Home', icon: 'mdi-home-city' },
                { title: 'My Account', icon: 'mdi-account' },
                { title: 'Users', icon: 'mdi-account-group-outline' },
              ],
              mini: true,
                activeBtn: 1,
                loggedIn: false,
                isMobile:false,
            }
        },
        beforeDestroy () {
    if (typeof window === 'undefined') return

    window.removeEventListener('resize', this.onResize, { passive: true })
  },

        mounted() {
          this.onResize()

      window.addEventListener('resize', this.onResize, { passive: true })
    this.setupFirebase();
  if(this.$vuetify.breakpoint.mobile) {
    this.drawer = false;
  }
  },
        computed: {
    currentUser() {
      console.log(firebase.auth().currentUser )
     return firebase.auth().currentUser;
    },
  },
        methods: {
          onResize () {
       this.isMobile = window.innerWidth < 600
    //   this.drawer = !this.isMobile
     },
          setupFirebase() {
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          // User is signed in.
          console.log("signed in");
          this.loggedIn = true;
        } else {
          // No user is signed in.
          this.loggedIn = false;
          console.log("signed out", this.loggedIn);
        }
      });
    },
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.push('/connect');
        });
    }
  },



    }
</script>
