<template>
  <div >
    <section id="hero">
        <v-row no-gutters>
          <v-img
            :height="'calc(100vh - ' + $vuetify.application.top + 'px)'"
            src="/img/home/main.jpg"
            lazy-src="/img/home/main_lazy.jpg"
          >
            <v-theme-provider dark>
              <v-container fill-height>
                <v-row
                  align="center"

                  justify="center"
                  pa-0

                >
                  <v-col

                    class="white--text text-center title_edit mb-4"
                    cols="12"
                    tag="h1"
                  >

                  <v-img  class="size_img"
                  contain
src="/img/logo/logo.png"

></v-img>



                    <span
                      class="font-weight-black   test_font "
                    >
                 Développons le local
                    </span>
<br>
<br>
                  </v-col>

                  <v-btn class="mx-2 mb-2" width="300" to="/map" x-large depressed color="secondary">Trouver un commerce</v-btn>

                  <v-btn class="mx-2 mb-2" width="300" to="/createaccount" x-large depressed color="secondary">Se référencer</v-btn>

                  <v-menu offset-y>
                 <template v-slot:activator="{ on, attrs }">
                 <v-btn
                 color="secondary"
                 width="300"
                  x-large
                 v-bind="attrs"
                 v-on="on"
                 class="mx-2 mb-2"
                 >
                 Comprendre
                 </v-btn>
                 </template>
                 <v-list>
                 <v-list-item
                 v-for="(item, index) in items"
                 :key="index"
                  :href="'#'+item.href"
                 >
                 <v-list-item-title>{{ item.title }}</v-list-item-title>
                 </v-list-item>
                 </v-list>
                 </v-menu>




                </v-row>
              </v-container>
            </v-theme-provider>
          </v-img>
        </v-row>
      </section>



          <section
            id="commerce"
            class="grey lighten-3"
          >
            <div class="py-12"></div>

            <v-container class="text-center">
              <h2 class="display-2 font-weight-bold mb-3">Vous êtes commerçant</h2>

              <v-responsive
                class="mx-auto mb-12"
                width="56"
              >
                <v-divider class="mb-1"></v-divider>

                <v-divider></v-divider>
              </v-responsive>

              <v-row>
                <v-col v-for="(p, i) in features" :key="i" cols="12"  md="4" >
                  <v-card   color="grey lighten-5"   flat>
                    <v-theme-provider dark>
                      <div>
                        <v-img
   height="250"
   :src="p.img"
   :lazy-src="p.img_lazy"
 ></v-img>


                      </div>
                    </v-theme-provider>

                    <v-card-title  class="justify-center"><div style="min-height:110px;"><p class="justify-center text-center font-weight-black  title_card text-uppercase">
{{p.title}}
</p></div>
</v-card-title>
                    <v-card-actions class="text-center">


      <v-spacer></v-spacer>
      <v-btn  color="primary"   @click="p.show = !p.show"  text>
      En savoir plus
    </v-btn>
      <v-btn
        icon
        @click="p.show = !p.show"
      >
        <v-icon>{{p.show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-actions>


                    <v-expand-transition>
     <div v-show="p.show">
       <v-divider></v-divider>

       <v-card-text
         class="subtitle-1 text-justify"
         v-html="p.text"
       >
       </v-card-text>
     </div>
   </v-expand-transition>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>

            <div class="py-12"></div>
          </section>


          <section
            id="client"
            class="grey lighten-3"
          >


            <v-container class="text-center">
              <h2 class="display-2 font-weight-bold mb-3">Vous êtes client</h2>

              <v-responsive
                class="mx-auto mb-12"
                width="56"
              >
                <v-divider class="mb-1"></v-divider>

                <v-divider></v-divider>
              </v-responsive>

              <v-row>
                <v-col v-for="(p, i) in features_customer" :key="i" cols="12"  md="4" >
                  <v-card    color="grey lighten-5"   flat>
                    <v-theme-provider dark>
                      <div>
                        <v-img
   height="250"
   :src="p.img"
   :lazy-src="p.img_lazy"
 ></v-img>


                      </div>
                    </v-theme-provider>

                    <v-card-title  class="justify-center"><div style="min-height:110px;"><p class="justify-center text-center font-weight-black  title_card text-uppercase">
{{p.title}}
</p></div>

                  </v-card-title>

                    <v-card-actions class="text-center">


      <v-spacer></v-spacer>
      <v-btn  color="primary"  text>
      En savoir plus
    </v-btn>
      <v-btn
        icon
        @click="p.show = !p.show"
      >
        <v-icon>{{p.show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-actions>


                    <v-expand-transition>
     <div v-show="p.show">
       <v-divider></v-divider>

       <v-card-text
         class="subtitle-1 text-justify"
         v-html="p.text"
       >
       </v-card-text>
     </div>
   </v-expand-transition>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>


          </section>

          <subscribe></subscribe>
  </div>
</template>

<script>
import subscribe from '@/components/subscribe'

    export default {
      components: {
          subscribe,
      },
        data () {
            return {
              show:false,
              range: [0, 10000],
           select:'Popularity',
           options: [
               'Default',
               'Popularity',
               'Relevance',
               'Price: Low to High',
               'Price: High to Low',
           ],
           page:1,
           breadcrums: [
               {
                   text: 'Home',
                   disabled: false,
                   href: 'breadcrumbs_home',
               },
               {
                   text: 'Clothing',
                   disabled: false,
                   href: 'breadcrumbs_clothing',
               },
               {
                   text: 'T-Shirts',
                   disabled: true,
                   href: 'breadcrumbs_shirts',
               },
           ],
           min:0,
           max:10000,
              items:[{title: 'Vous êtes commercant', href:'commerce'}, {title:'Vous êtes client', href:'client'}],
              features: [

                {
                icon: 'mdi-account-group-outline',
                title: 'Une solution citoyenne ',
                text: 'SOLVE c’est avant tout une <b>solution citoyenne</b> aux défis engendrés par les crises actuelles. Au fondement de ce projet : la volonté de proposer une <b>alternative aux méthodes de ventes conventionnelles</b> qui souvent n’avantagent pas les producteurs et commerçants locaux, maillons essentiels du tissu économique et social du pays. Annuaire en ligne gratuit, recensant producteurs et commerces de proximité, SOLVE se dresse en véritable <b>vitrine virtuelle de vos prestations</b> et assure ainsi le <b>maintien de votre activité</b> grâce au numérique.',
                 img:'/img/home/commerce_1.jpg',
                  img_lazy:'/img/home/commerce_1_lazy.jpg',
                  show:false,
                },
                {
                icon: 'mdi-update',
                title: 'Une garantie de pérennité',
                text: 'Si elle est avant tout une <b>solution rapide et concrète</b> au contexte actuel, la plateforme SOLVE présente de nombreux avantages sur le long terme. <b>Numériser aujourd’hui</b>, à l’approche des fêtes de fin d’année, est <b>une question de survie</b> pour de nombreuses entreprises françaises. Pourtant la numérisation de votre activité est aussi l’assurance de nouveaux débouchés au lendemain de la crise sanitaire.  La présence web c’est la <b>garantie d’une meilleure visibilité</b> et donc la possibilité de plus de visites en magasin. Le web facilite également le <b>rassemblement</b> et la <b>fidélisation</b> de vos clients, avec des moyens de communication plus nombreux et plus réactifs. De plus, une présence digitale vous permet de ne pas être dépendant des aléas « physiques » d’un magasin pour vendre (travaux du magasin ou dans la rue, vacances, jours de fermeture, etc.) les commandes continuent de se faire en ligne. Enfin, pourquoi ne pas voir plus large? Avec un recensement internet et la possibilité de livraison ou collecte, <b>la zone de chalandise</b> s’élargit et devient nationale. ',
                 img:'/img/home/commerce_2.jpg',
                 img_lazy:'/img/home/commerce_2_lazy.jpg',
                  show:true,
               },

                {
                icon: 'mdi-shield-outline',
                title: 'Un concept simple ',
                text: '<b>Gratuite et intuitive</b>, notre plateforme vous assure <b>gain de temps et efficacité</b>. Pour vous référencer une adresse mail et <b>quelques minutes suffisent</b>. Lors de votre inscription fournissez votre adresse afin d’être <b>facilement géolocalisés</b> pas nos clients, choisissez ensuite la catégorie de produits à la vente chez vous ainsi que les moyens de paiement et modes de livraison disponibles et enfin, entrez les informations nécessaires à la prise de contact. Vous pouvez même importer une photo, ainsi qu’un fichier répertoriant tous vos produits. Le tour est joué. Vous bénéficiez maintenant d’une plus <b>grande visibilité à échelle locale</b>.',
                 img:'/img/home/commerce_3.jpg',
                 img_lazy:'/img/home/commerce_3_lazy.jpg',
                show:false,},

     ],
     features_customer: [
       {
         icon: 'mdi-account-group-outline',
         title: 'Une initiative solidaire et responsable ',
         text: 'Saviez-vous que les <b>entreprises françaises</b> produisent tous les produits nécessaires à la vie quotidienne? Grand nombre d’entre elles luttent en permanence pour rester en France et ne pas plier face aux exigences de la globalisation. Le contexte actuel rend ce combat plus déloyal que jamais. Face à ce constat, SOLVE souhaite proposer une <b>alternative aux méthodes de vente conventionnelles</b> afin d’assurer la <b>pérennité des petits commerces et entreprises de proximité</b>. Grâce à SOLVE, annuaire en ligne recensant les producteurs et commerçants locaux, changez vos habitudes de consommation et participez à la <b>dynamisation du tissu économique et social de votre région</b> tout en préservant l’environnement.',
         img:'/img/home/client_1.jpg',
         img_lazy:'/img/home/client_1_lazy.jpg',
         show:false,
       },
       {
         icon: 'mdi-update',
         title: 'Une garantie de qualité et d’économie ',
         text: '<b>Mouvement citoyen</b>, la consommation locale est aussi la <b>garantie d’avantages personnels</b> évidents. Contrairement aux idées reçues, elle est un <b>moyen efficace de préserver nos portes monnaies</b>. Par la <b>suppression des intermédiaires</b> (transports, étalages de grandes enseignes) et des surplus (packagings, marketing), les commerçants locaux peuvent vous proposer leurs produits au meilleur prix. C’est également une <b>assurance de qualité</b>, consommer local est la seule garantie du respect des règles d\'hygiène et de sécurité en vigueur. ',
       img:'/img/home/client_2.jpg',
       img_lazy:'/img/home/client_2_lazy.jpg',
       show:false,

     },
       {
         icon: 'mdi-shield-outline',
         title: 'Un concept simple                         ',
         text: '<b>Gratuite et intuitive</b>, notre plateforme vous assure <b>gain de temps et efficacité</b>. Grâce à la <b>fonction géolocalisation</b>, délimitez un périmètre de recherche, ensuite il vous suffit de sélectionner une <b>catégorie de produits</b> et vos <b>préférences de paiement et livraison</b>. Le tour est joué. Les commerces et producteurs à proximité s’affichent ainsi que toutes les informations nécessaires à la prise de contact.',
       img:'/img/home/client_3.jpg',
       img_lazy:'/img/home/client_3_lazy.jpg',
     show:false,},

],
            }
        },
        created:function() {

          console.log(this.$vuetify.application.top)
        }

    }
</script>
<style>
  .v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .5;
    position: absolute;
    width: 100%;
  }
  .title_edit {
    color:white;

    background-color:rgba(0, 1, 0, .5);

  }
  @font-face {
  font-family: "Melind";
  src: url("/style/Melinda.otf") format("opentype")
  }
  @font-face {
  font-family: "Amala";
  src: url("/style/amalashazia.ttf")
  }
  @font-face {
  font-family: "Aerotis";
  src: url("/style/Aerotis.ttf") format("opentype")
  }

  @font-face {
  font-family: "SundaySpring";
  src: url("/style/SundaySpring.ttf") format("opentype")
  }


  @font-face {
  font-family: "Mattahary";
  src: url("/style/Mattahary.ttf") format("opentype")
  }


  .test_font {
  font-family: 'SundaySpring', Arial, sans-serif;
  font-weight:normal;
  font-style:normal;



  }

  @media screen and (min-width: 900px) {
  .test_font {
    font-size: 55px;

  }
  .size_img{
    height: 120px;
    margin-bottom: 80px;
  }
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */


@media screen and (max-width: 900px) {
.test_font{
    font-size: 25px;
      margin-top: 40px;
  }
  .size_img {
    height: 80px;
    margin-bottom: 40px;
  }
}


    .title_card {

       word-break: normal;
    }

</style>
